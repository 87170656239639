<template>
  <a-modal v-model="visible" title="密码修改" @ok="save" width="450px">
    <a-form-model layout="vertical" ref="form" :model="form" :rules="rules" class="edp-form">
<!--      <a-form-model-item label="旧密码" prop="old_password">-->
<!--        <a-input-password type="password" autocomplete="new-password"-->
<!--                          v-model="form.old_password"></a-input-password>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="新密码" prop="user_password">
        <a-input-password type="password" autocomplete="new-password"
                          v-model="form.user_password"></a-input-password>
      </a-form-model-item>
      <a-form-model-item label="确认密码" prop="check_password">
        <a-input-password type="password" autocomplete="new-password"
                          v-model="form.check_password"></a-input-password>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {fetch} from "@/utils/request.js";
import {JSEncrypt} from "jsencrypt";

export default {
  name: "accountModal",
  data() {
    return {
      visible: false,
      form: {
        oid: '',
        user_password: '',
        check_password: ''
      },
      backup: {},
      rules: {
        check_password: [{required: true, validator: this.validatePass2, trigger: 'blur'}],
      },
      public_key: "",
      password_input: "",
    };
  },
  created() {
    this.backup = JSON.parse(JSON.stringify(this.form))
    this.getMyconfig("global_config", "public_key");
  },
  methods: {
    open(row) {
      let {oid} = row
      this.form = {
        ...this.backup,
        oid
      };
      this.visible = true;
    },
    getMyconfig(type, code) {
      let url = "/cpy/cat/retrieveportal/global/pub";
      fetch("post", url, {
        cat_type: type,
        cat_code: code,
      }).then((res) => {
        if (res && res.status === 0) {
          if (res.data.datas.length > 0) {
            this.public_key = res.data.datas[0].field_value;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let encryptor = new JSEncrypt() // 新建JSEncrypt对象
          encryptor.setPublicKey(this.public_key) // 设置公钥
          let user_password = encryptor.encrypt(this.form.user_password) // 对密码进行加密
          let query = {
            user_password,
            oid: this.form.oid
          }
          fetch("post", '/cpyaccount/pwd/update', query).then((res) => {
            if (res.status === 0) {
              this.$message.success("修改成功");
              this.visible = false;
              this.$emit("reload");
            } else {
              this.$message.error(res.message);
            }
          });
        }
      })
    },
    validatePass2(rule, value, callback) {
      if (value !== this.form.user_password) {
        callback(new Error('两次输入的密码不一致！'));
      } else {
        callback();
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  padding: 20px 24px;
}
</style>
