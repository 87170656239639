<template>
  <a-modal v-model="visible" :title="(type === 'create' ? '创建' : '修改') + '人员'" @ok="save" width="700px">
    <a-form-model layout="vertical" ref="form" :model="form" class="edp-form">
      <div class="line-2">
        <a-form-model-item label="用户名" prop="username">
          <a-input v-model="form.username"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="type === 'create'"  label="密码" prop="password_input">
          <a-input v-model="password_input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="姓名" prop="name">
          <a-input v-model="form.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="邮箱" prop="email">
          <a-input v-model="form.email"></a-input>
        </a-form-model-item>
        <a-form-model-item label="联系电话" prop="mobile">
          <a-input v-model="form.mobile"></a-input>
        </a-form-model-item>
        <a-form-model-item label="公司" prop="company_oid">
          <auto-input
              class="size-middle"
              url="/company/retrieve"
              value-key="oid"
              label-key="company_name"
              v-model="form.company_oid"
              :label.sync="form.company_name"
          />
        </a-form-model-item>
        <a-form-model-item label="岗位">
          <a-input v-model="form.title"></a-input>
        </a-form-model-item>
      </div>
      <a-form-model-item label="角色分配" prop="checked">
        <a-checkbox-group
            v-model="checked"
            :options="roles"
        >
          <template slot="label" slot-scope="row">
            <div class="user-row">
              <a-space>
                <div class="name">{{ row.name }}</div>
              </a-space>
            </div>
          </template>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="人员级别" prop="sec_level">
        <a-radio-group v-model="form.sec_level">
          <a-radio value="0">
            0
          </a-radio>
          <a-radio value="1">
            1
          </a-radio>
          <a-radio value="2">
            2
          </a-radio>
          <a-radio value="3">
            3
          </a-radio>
          <a-radio value="4">
            4
          </a-radio>
          <a-radio value="5">
            5
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {fetch} from "@/utils/request.js";
import AutoInput from "@/components/autoInput.vue";
import {JSEncrypt} from "jsencrypt";

export default {
  name: "accountModal",
  components: { AutoInput },
  data() {
    return {
      type: "create",
      visible: false,
      loading: false,
      form: {
        sec_level: ''
      },
      roles: [],
      checked: [],
      public_key: "",
      password_input: "",
    };
  },
  created() {
    this.getAllRoles();
    this.getMyconfig("global_config", "public_key");
  },
  methods: {
    open(type, item) {
      this.type = type;
      this.form = {};
      this.checked = [];
      this.visible = true;
      if (type === "update") {
        this.form = item;
        this.checked = this.form.codes.split(",");
      }
    },
    getMyconfig(type, code) {
      let url = "/cpy/cat/retrieveportal/global/pub";
      fetch("post", url, {
        cat_type: type,
        cat_code: code,
      }).then((res) => {
        if (res && res.status === 0) {
          if (res.data.datas.length > 0) {
            this.public_key = res.data.datas[0].field_value;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 查询所有角色
    getAllRoles() {
      fetch("post", "/company/role/all", {}).then((res) => {
        if (res.status === 0) {
          let data = res.data.datas;
          if (data.length > 0) {
            data.forEach((e) => {
              e["value"] = e.role_code;
            });
            this.roles = data;
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    save() {
      if (this.form.username === "") {
        this.$message.error("请输入用户名！");
        return;
      }
      if (this.type === "create" && this.password_input === "") {
        this.$message.error("请输入密码！");
        return;
      }
      let encryptor = new JSEncrypt(); // 新建JSEncrypt对象
      encryptor.setPublicKey(this.public_key); // 设置公钥
      let rsaPassWord = encryptor.encrypt(this.password_input); // 对密码进行加密
      this.form.user_password = rsaPassWord;
      this.form.codes = this.checked.join(",");
      let url = "/cpyaccount/create";
      if (this.type === "update") {
        url = "/cpyaccount/update";
      }
      fetch("post", url, this.form).then((res) => {
        if (res.status === 0) {
          this.$message.success("保存成功");
          this.visible = false;
          this.$emit("reload");
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  padding: 20px 24px;
}

/deep/.ant-form-item {

  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
    width: auto;
  }

  .ant-checkbox-group-item {
    display: flex;
    align-items: center;

    .ant-checkbox {
      top: 0;
    }
  }
}
</style>
