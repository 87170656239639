<template>
  <div class="app-main-content">
    <div class="container d-flex">
      <settings-left-menu></settings-left-menu>
      <card title="平台成员列表页" style="width: calc(100% - 326px)">
        <template #tools>
          <div class="item">
            <a-button class="search" @click="$refs.accountModal.open('create')">新增人员</a-button>
          </div>
          <div class="right">
            <div class="item">
              <span class="label">搜索</span>
              <a-input class="search-input" v-model="listQuery.keywords" placeholder="输入用户名、邮箱、电话、公司名称">
                <a-icon slot="prefix" type="search" />
              </a-input>
            </div>
            <div class="item">
              <a-button class="search" @click="getList">查询</a-button>
            </div>
          </div>
        </template>
        <div style="padding: 0 20px;">
          <a-table
              :data-source="list"
              :pagination="pagination"
              @change="paginationChange"
              rowKey="oid"
          >
            <a-table-column title="用户名">
              <template slot-scope="scope, row">
                <div class="line-center">
                  <a-avatar class="avatar" :size="21" :src="row.pic" />
                  {{ row.username }}
                </div>
              </template>
            </a-table-column>
            <a-table-column title="姓名" data-index="name"></a-table-column>
            <a-table-column title="岗位" data-index="title"></a-table-column>
            <a-table-column title="邮箱" data-index="email"></a-table-column>
            <a-table-column title="联系电话" data-index="mobile"></a-table-column>
            <a-table-column title="公司名称" data-index="company_name"></a-table-column>
            <a-table-column title="角色" data-index="roles"></a-table-column>
            <!-- <a-table-column title="日期" data-index="create_time"></a-table-column> -->
            <a-table-column title="操作" width="250px">
              <template #default="_, row">
                <div>
                  <a-button style="padding: 0;margin-right: 7px;" type="link"
                            @click="$refs.accountModal.open('update', row)">修改
                  </a-button>
                  <a-button style="padding: 0;margin-right: 7px;" type="link" @click="deleteAccount(row)">删除</a-button>
                  <a-button style="padding: 0;margin-right: 7px;" type="link" v-if="row.status === '10'" @click="wechatAuthorization(row, '0')">微信授权</a-button>
                  <a-button style="padding: 0;margin-right: 7px;" type="link" v-else-if="row.status === '0'" @click="wechatAuthorization(row, '10')">取消授权</a-button>
                  <a-button style="padding: 0;" type="link" v-if="isAdmin" @click="editPassword(row)">密码修改</a-button>
                </div>
              </template>
            </a-table-column>
          </a-table>
        </div>
      </card>
    </div>
    <account-modal @reload="getList" ref="accountModal"></account-modal>
    <password-modal ref="passwordModal"></password-modal>
  </div>
</template>

<script>
import listMixin from "@/utils/mixins/listMixin.js";
import AccountModal from "@/components/accountModal.vue";
import {fetch} from "@/utils/request.js";
import catMixin from "@/utils/mixins/catMixin.js";
import passwordModal from "@/components/passwordModal.vue";
import settingsLeftMenu from "@/components/settingsLeftMenu.vue";

export default {
  components: { AccountModal, settingsLeftMenu, passwordModal },
  mixins: [listMixin, catMixin],
  data() {
    return {
      cat: {
        company_role: [],
        country: [],
      },
      listMixinOptions: {
        url: "/cpyaccount/retrieve",
        autoRun: false,
      },
      listQuery: {
        keywords: "",
      },
      wechatStatus: '',
    };
  },
  computed: {
    isAdmin() {
      let roles = JSON.parse(localStorage.getItem('company_info')).Roles
      return !!roles.find(item => item.role_code === 'sys_admin')
    }
  },
  created() {
    if (this.$route.params.company) {
      this.listQuery.keywords = this.$route.params.company
      return this.search()
    }
    this.getList()
  },
  methods: {
    deleteAccount(row) {
      this.$confirm({
        title: `确定要删除账号 ${row.username} 吗?`,
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          fetch("post", "/cpyaccount/delete", {
            oid: row.oid,
          }).then((res) => {
            if (res.status === 0) {
              this.$message.success("删除成功！");
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    wechatAuthorization(row, status) {
      fetch("post", "/cpyaccount/update", {
        oid: row.oid,
        status
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success("更新成功！");
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    editPassword(row) {
      console.log(row);
      this.$refs.passwordModal.open(row)
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;

  /deep/ .edp-card-body {
    overflow-y: auto;
  }
}
</style>
