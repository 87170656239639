<template>
  <div class="left-menu">
    <div class="left-menu-title">配置项</div>
    <edp-tree :data="list" @change="clickTree" row-key="menu_code">
      <template v-slot="{row}">
        <div class="tree-row">
          <div class="tree-row-info">
            <div class="name">{{row.menu_name}}</div>
          </div>
        </div>
      </template>
    </edp-tree>
  </div>
</template>

<script>
import edpTree from "@/components/edpTree.vue";
import {fetch} from "@/utils/request";
export default {
  name: "settingsLeftMenu",
  data(){
    return{
      list: []
    }
  },
  components: {edpTree},
  created() {
  },
  mounted() {
    this.getMenu()
  },
  methods: {
    getMenu() {
      fetch("post", "account/usermenu", {father_code: 'setting_manage'}).then(res => {
        if (res.status === 0) {
          // console.log(res.data.menus);
          this.list = res.data.menus
        }
      })
    },
    clickTree(row) {
      console.log(row);
      this.$router.push({path: row.menu_url})
    }
  }
}
</script>

<style scoped lang="less">
.left-menu{
  width: 320px;
  margin-right: 6px;
  padding: 24px 20px;
  flex-shrink: 0;
  border-radius: 4px 4px 4px 4px;
  background: #f8f8f8ff;
  box-shadow: 0 0 29px 0 #0000000d;
  .left-menu-title{
    font-weight: 500;
    line-height: 16px;
    font-size: 16px;
    color: #5D5D5D;
    margin-bottom: 15px;
  }
  .tree-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    .tree-row-info{
      display: flex;
    }
    .tree-row-dropdown{
      padding-right: 5px;
    }
    .dir {
      margin-right: 5px;
      height: 22px;
    }
    .menu-icon .iconfont{
      font-size: 16px;
      font-weight: 500;
    }
  }
  /deep/ .edp-tree-item {
    &.active {
      >.tree-item-row >.tree-row {
        .menu-icon {
          display: block;
        }
      }
    }
    >.tree-item-row {
      .tree-row{
        .name{
          width: 180px;
        }
      }
      .menu-icon {
        display: none;
      }
      &:hover {
        .menu-icon {
          display: block;
        }
      }
    }
  }

}
</style>
